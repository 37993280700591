
import React from "react";
import { Link } from "react-router-dom";

function DualTypeChart() {
	return (
		<>
			<section class="single section-sm pb-0">
				<div class="container">
					<div class="row">
						<div class="col-lg-12">
							<div class="p-lg-5 p-4 bg-white">
								<h2 class="mb-5">Evo Dual Type Charts</h2>
								<p>Each Evo move has a type, so when the Evo uses that move and it does damage of that type.  Then based on the type(s) of the Evo that is being attacked the damage could be modified based on the chart below.  Dual-Type specific charts coming soon!</p>
								<div class="content">
									<img src="https://cdn.discordapp.com/attachments/965515857704939551/1034199212323258421/Evoverses_Type_Chart_with_Dual_Type.PNG" class="img-fluid" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default DualTypeChart;