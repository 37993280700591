const Moves = [
{'Name':'Scratch','Type':'Earth','Power':40,'Accuracy':100,'Uses':42,'Contact':false,'MoveType':'Physical','Description1':'','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Drainage','Type':'Plant','Power':40,'Accuracy':100,'Uses':30,'Contact':true,'MoveType':'Special','Description1':'Deals X damage to the opponent','Description2':'User recovers X/2 health','Description3':'','Description4':'','Description5':''},
{'Name':'Curl Up','Type':'Earth','Power':0,'Accuracy':100,'Uses':42,'Contact':false,'MoveType':'Status','Description1':'Increases +1 user\'s Defense','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Bug Bite','Type':'Bug','Power':40,'Accuracy':100,'Uses':30,'Contact':false,'MoveType':'Physical','Description1':'40% chance to paralyze the opponent','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Bite','Type':'Monster','Power':70,'Accuracy':100,'Uses':18,'Contact':false,'MoveType':'Physical','Description1':'20% chance to decrease -1 opponent\'s Defense','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Suction','Type':'Bug','Power':70,'Accuracy':100,'Uses':12,'Contact':false,'MoveType':'Physical','Description1':'The user recovers X/2 the HP lost by the target, rounded up','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Seismic Blitz','Type':'Earth','Power':120,'Accuracy':100,'Uses':18,'Contact':false,'MoveType':'Physical','Description1':'Deals X damage to the opponent','Description2':'User loses X/3 health','Description3':'','Description4':'','Description5':''},
{'Name':'Tackle','Type':'Earth','Power':45,'Accuracy':100,'Uses':42,'Contact':false,'MoveType':'Physical','Description1':'','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Bubble Bomb','Type':'Water','Power':40,'Accuracy':100,'Uses':30,'Contact':true,'MoveType':'Special','Description1':'20% chance to decrease -1 opponent\'s precision','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Sharpen','Type':'Earth','Power':0,'Accuracy':100,'Uses':42,'Contact':false,'MoveType':'Status','Description1':'Increases +1 user\'s Attack','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Osmosis','Type':'Water','Power':70,'Accuracy':100,'Uses':12,'Contact':false,'MoveType':'Special','Description1':'Deals X damage to the opponent','Description2':'User recovers X/2 health','Description3':'','Description4':'','Description5':''},
{'Name':'Silk Shot','Type':'Bug','Power':0,'Accuracy':100,'Uses':24,'Contact':false,'MoveType':'Status','Description1':'Lowers the target\'s Speed by -2 stages','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Sturdiness','Type':'Earth','Power':0,'Accuracy':100,'Uses':30,'Contact':false,'MoveType':'Status','Description1':'Increases +1 user\'s Defense','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Roar','Type':'Monster','Power':0,'Accuracy':100,'Uses':30,'Contact':false,'MoveType':'Status','Description1':'Increases +1 user\'s Attack','Description2':'Decreases -1 opponent\'s Attack','Description3':'','Description4':'','Description5':''},
{'Name':'Growth','Type':'Plant','Power':0,'Accuracy':100,'Uses':24,'Contact':false,'MoveType':'Status','Description1':'Increases +1 user\'s Attack','Description2':'Increases +1 user\'s Defense','Description3':'','Description4':'','Description5':''},
{'Name':'Ancestral Beam','Type':'Earth','Power':75,'Accuracy':100,'Uses':18,'Contact':true,'MoveType':'Special','Description1':'20% chance to increase +1 user\'s Attack and +1 Special Attack','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Seed Shot','Type':'Bug','Power':10,'Accuracy':100,'Uses':18,'Contact':true,'MoveType':'Physical','Description1':'The user throws 4-8 projectiles','Description2':'Each projectile does 10 damage','Description3':'','Description4':'','Description5':''},
{'Name':'Fury Dance','Type':'Earth','Power':0,'Accuracy':100,'Uses':30,'Contact':false,'MoveType':'Status','Description1':'Increases +2 user\'s Attack','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Analysis','Type':'Earth','Power':0,'Accuracy':100,'Uses':30,'Contact':false,'MoveType':'Status','Description1':'Decreases -1 opponent\'s Defense','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Swagger','Type':'Air','Power':0,'Accuracy':100,'Uses':24,'Contact':false,'MoveType':'Status','Description1':'Decreases -1 opponent\'s Attack','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Triple Attack','Type':'Monster','Power':15,'Accuracy':95,'Uses':30,'Contact':false,'MoveType':'Physical','Description1':'User attacks 3 times','Description2':'The attack does 5 more damage each hit: 15, 20, 25','Description3':'','Description4':'','Description5':''},
{'Name':'Kick Attack','Type':'Earth','Power':70,'Accuracy':100,'Uses':18,'Contact':false,'MoveType':'Physical','Description1':'','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Muddy Blast','Type':'Earth','Power':60,'Accuracy':95,'Uses':24,'Contact':true,'MoveType':'Special','Description1':'50% chance to decrease -1 opponent\'s Precision','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Leer','Type':'Dark','Power':0,'Accuracy':100,'Uses':42,'Contact':false,'MoveType':'Status','Description1':'Decrease -1 opponent\'s Defense','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Slash','Type':'Monster','Power':85,'Accuracy':85,'Uses':18,'Contact':false,'MoveType':'Physical','Description1':'20% chance to decrease -1 opponent\'s Defense','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Poison Arrow','Type':'Corrupt','Power':70,'Accuracy':100,'Uses':18,'Contact':true,'MoveType':'Physical','Description1':'10% chance to poison the opponent','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Lunge','Type':'Earth','Power':90,'Accuracy':90,'Uses':24,'Contact':false,'MoveType':'Physical','Description1':'Deals X damage to the opponent','Description2':'User loses X/4 health','Description3':'','Description4':'','Description5':''},
{'Name':'Stamp','Type':'Earth','Power':75,'Accuracy':100,'Uses':18,'Contact':false,'MoveType':'Physical','Description1':'20% chance to confuse the opponent','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Diamond Charge','Type':'Mineral','Power':120,'Accuracy':100,'Uses':12,'Contact':false,'MoveType':'Physical','Description1':'Deals X damage to the opponent','Description2':'Move has 1 of 2 knockback effects:','Description3':'If user has Mineral type, user loses X/4 health','Description4':'Otherwise, user loses X/2 health','Description5':''},
{'Name':'Hardy Claw','Type':'Monster','Power':50,'Accuracy':100,'Uses':24,'Contact':false,'MoveType':'Physical','Description1':'30% chance to increase +1 user\'s Defense','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Frantic Charge','Type':'Earth','Power':70,'Accuracy':100,'Uses':12,'Contact':false,'MoveType':'Physical','Description1':'+1 priorty','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Zen Fury','Type':'Monster','Power':80,'Accuracy':100,'Uses':12,'Contact':true,'MoveType':'Special','Description1':'Decreases -1 opponent\'s Speed','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Double Chop','Type':'Bug','Power':40,'Accuracy':90,'Uses':18,'Contact':false,'MoveType':'Physical','Description1':'Hits the opponent 2 times: base damage each hit','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Flash','Type':'Ether','Power':0,'Accuracy':100,'Uses':24,'Contact':false,'MoveType':'Status','Description1':'Decreases -1 opponent\'s Special Attack','Description2':'Decreases -1 opponent\'s Special Defense','Description3':'Decreases -1 opponent\'s Precision','Description4':'','Description5':''},
{'Name':'Cynicism','Type':'Dark','Power':0,'Accuracy':100,'Uses':30,'Contact':false,'MoveType':'Status','Description1':'Neutralizes opponent stat modifications','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Evil Sphere','Type':'Dark','Power':40,'Accuracy':100,'Uses':42,'Contact':true,'MoveType':'Special','Description1':'','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Warming Light','Type':'Light','Power':0,'Accuracy':100,'Uses':42,'Contact':false,'MoveType':'Status','Description1':'Increases +1 user\'s Special Attack','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Dark Force','Type':'Dark','Power':60,'Accuracy':100,'Uses':24,'Contact':true,'MoveType':'Special','Description1':'50% chance to increase +1 user\'s Special Attack','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Ominous Pulse','Type':'Dark','Power':75,'Accuracy':100,'Uses':18,'Contact':true,'MoveType':'Special','Description1':'','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Wing Smack','Type':'Air','Power':40,'Accuracy':100,'Uses':42,'Contact':false,'MoveType':'Physical','Description1':'','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Savage Cut','Type':'Monster','Power':60,'Accuracy':100,'Uses':24,'Contact':false,'MoveType':'Physical','Description1':'40% chance to decrease -1 opponent\'s Defense','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Hypnotism','Type':'Dark','Power':0,'Accuracy':100,'Uses':12,'Contact':false,'MoveType':'Status','Description1':'Sleeps the opponent for 1-2 turns','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Lunar Beam','Type':'Light','Power':90,'Accuracy':100,'Uses':12,'Contact':true,'MoveType':'Special','Description1':'20% chance to decrease -1 opponent\'s Special Defense','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Moon Recovery','Type':'Dark','Power':0,'Accuracy':100,'Uses':12,'Contact':false,'MoveType':'Status','Description1':'User gains maxHP/2 health','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Mega Drainage','Type':'Plant','Power':60,'Accuracy':100,'Uses':18,'Contact':true,'MoveType':'Special','Description1':'Deals X damage to the opponent','Description2':'User recovers X/2 health','Description3':'','Description4':'','Description5':''},
{'Name':'Healing Pulse','Type':'Light','Power':0,'Accuracy':100,'Uses':18,'Contact':false,'MoveType':'Status','Description1':'User cures status condition','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Fi B','Type':'Monster','Power':0,'Accuracy':100,'Uses':24,'Contact':false,'MoveType':'Status','Description1':'Confuses the opponent','Description2':'Increases +2 opponent\'s Special Attack','Description3':'','Description4':'','Description5':''},
{'Name':'Stellar Radiance','Type':'Light','Power':0,'Accuracy':100,'Uses':24,'Contact':false,'MoveType':'Status','Description1':'Increases +1 user\'s Special Attack','Description2':'Increases +1 user\'s Special Defense','Description3':'','Description4':'','Description5':''},
{'Name':'Cyclone','Type':'Air','Power':70,'Accuracy':90,'Uses':18,'Contact':true,'MoveType':'Special','Description1':'30% chance to confuse the opponent','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Light Flash','Type':'Light','Power':40,'Accuracy':100,'Uses':42,'Contact':false,'MoveType':'Special','Description1':'Decreases -1 opponent\'s Precision','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Light Burst','Type':'Light','Power':15,'Accuracy':90,'Uses':18,'Contact':true,'MoveType':'Special','Description1':'The user shoots 4-8 projectiles','Description2':'Each projectile does 15 damage','Description3':'','Description4':'','Description5':''},
{'Name':'Solar Blast','Type':'Light','Power':95,'Accuracy':100,'Uses':12,'Contact':true,'MoveType':'Special','Description1':'20% chance to poison the opponent','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Dust Cloud','Type':'Air','Power':0,'Accuracy':100,'Uses':42,'Contact':false,'MoveType':'Status','Description1':'Decreases -1 opponent\'s Precision','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Dust Storm','Type':'Air','Power':50,'Accuracy':100,'Uses':30,'Contact':true,'MoveType':'Physical','Description1':'50% chance to decrease -1 opponent\'s Precision','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Nitro Flame','Type':'Fire','Power':60,'Accuracy':90,'Uses':18,'Contact':false,'MoveType':'Physical','Description1':'+1 priority','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Raptor Charge','Type':'Air','Power':110,'Accuracy':90,'Uses':6,'Contact':false,'MoveType':'Physical','Description1':'Decreases -1 user\'s Special Attack','Description2':'Decreases -1 user\'s Special Defense','Description3':'','Description4':'','Description5':''},
{'Name':'Torpedo','Type':'Water','Power':50,'Accuracy':100,'Uses':42,'Contact':false,'MoveType':'Physical','Description1':'+1 priority','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Silk Cloak','Type':'Bug','Power':0,'Accuracy':100,'Uses':24,'Contact':false,'MoveType':'Status','Description1':'Increases +1 user\'s Defense','Description2':'Increases +1 user\'s Special Defense','Description3':'','Description4':'','Description5':''},
{'Name':'Shedding','Type':'Bug','Power':0,'Accuracy':100,'Uses':6,'Contact':false,'MoveType':'Status','Description1':'Increases +2 user\'s Speed','Description2':'Increases +1 user\'s Attack','Description3':'Increases +1 user\'s Special Attack','Description4':'Decreases -2 user\'s Defense','Description5':''},
{'Name':'Slimy Armor','Type':'Water','Power':0,'Accuracy':100,'Uses':30,'Contact':false,'MoveType':'Status','Description1':'Increases +2 user\'s Defense','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Deep Sleep','Type':'Dark','Power':0,'Accuracy':100,'Uses':30,'Contact':false,'MoveType':'Status','Description1':'User sleeps for 1-3 turns','Description2':'User recovers all health','Description3':'User cures status condition','Description4':'','Description5':''},
{'Name':'Polish','Type':'Mineral','Power':0,'Accuracy':100,'Uses':18,'Contact':false,'MoveType':'Status','Description1':'Raises the user\'s Speed by +2 stages','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Rockiness','Type':'Earth','Power':0,'Accuracy':100,'Uses':30,'Contact':false,'MoveType':'Status','Description1':'Increase +2 user\'s Defense','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Buzz','Type':'Bug','Power':50,'Accuracy':100,'Uses':30,'Contact':true,'MoveType':'Special','Description1':'20% chance to confuse the opponent','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Pollination','Type':'Bug','Power':90,'Accuracy':100,'Uses':12,'Contact':true,'MoveType':'Special','Description1':'10% chance to lower the target\'s Special Defense by -1 stage','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Fleeting Shine','Type':'Light','Power':50,'Accuracy':100,'Uses':30,'Contact':true,'MoveType':'Special','Description1':'+1 priority','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Plasma Beam','Type':'Ether','Power':70,'Accuracy':90,'Uses':18,'Contact':true,'MoveType':'Special','Description1':'20% chance to confuse the opponent','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Stellar Charge','Type':'Ether','Power':0,'Accuracy':100,'Uses':18,'Contact':false,'MoveType':'Status','Description1':'Increases +1 user\'s Defense','Description2':'Increases +1 user\'s Special Defense','Description3':'Increases +1 user\'s Precision','Description4':'','Description5':''},
{'Name':'Rip','Type':'Dark','Power':40,'Accuracy':100,'Uses':42,'Contact':false,'MoveType':'Physical','Description1':'50% chance to decrease -1 opponent\'s Defense','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Mean Bite','Type':'Corrupt','Power':40,'Accuracy':100,'Uses':30,'Contact':false,'MoveType':'Physical','Description1':'30% chance to paralyze the opponent','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Venom Bite','Type':'Bug','Power':30,'Accuracy':90,'Uses':30,'Contact':false,'MoveType':'Physical','Description1':'100% chance to poison the opponent','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Rancor Blow','Type':'Dark','Power':50,'Accuracy':100,'Uses':24,'Contact':false,'MoveType':'Physical','Description1':'Decreases -1 user\'s Special Attack','Description2':'Decreases -1 opponent\'s Attack','Description3':'','Description4':'','Description5':''},
{'Name':'Dark Fire','Type':'Fire','Power':20,'Accuracy':75,'Uses':42,'Contact':true,'MoveType':'Special','Description1':'100% chance to burn the opponent','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Acid Shot','Type':'Corrupt','Power':90,'Accuracy':80,'Uses':12,'Contact':true,'MoveType':'Special','Description1':'30% chance to burn the opponent','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Corrosion','Type':'Corrupt','Power':0,'Accuracy':100,'Uses':18,'Contact':false,'MoveType':'Status','Description1':'100% chance to poison the opponent','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Corrupted Beam','Type':'Corrupt','Power':100,'Accuracy':100,'Uses':6,'Contact':true,'MoveType':'Special','Description1':'','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Strategy','Type':'Corrupt','Power':0,'Accuracy':100,'Uses':12,'Contact':false,'MoveType':'Status','Description1':'Increases +1 user\'s Special Attack','Description2':'Increases +1 user\'s Precision','Description3':'','Description4':'','Description5':''},
{'Name':'High Voltage','Type':'Light','Power':90,'Accuracy':90,'Uses':18,'Contact':false,'MoveType':'Physical','Description1':'20% chance to paralyze the opponent','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Foam Blast','Type':'Water','Power':65,'Accuracy':100,'Uses':18,'Contact':true,'MoveType':'Special','Description1':'10% chance to paralyze the opponent','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Squall','Type':'Air','Power':50,'Accuracy':100,'Uses':42,'Contact':true,'MoveType':'Special','Description1':'','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Flower Cure','Type':'Plant','Power':0,'Accuracy':100,'Uses':30,'Contact':false,'MoveType':'Status','Description1':'Increases +1 user\'s Special Defense','Description2':'Cures user\'s status condition','Description3':'','Description4':'','Description5':''},
{'Name':'Mallet Smash','Type':'Earth','Power':90,'Accuracy':90,'Uses':12,'Contact':false,'MoveType':'Physical','Description1':'10% chance to decrease -1 opponent\'s Defense','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Forest Mace','Type':'Plant','Power':120,'Accuracy':100,'Uses':12,'Contact':false,'MoveType':'Physical','Description1':'Deals X damage to the opponent','Description2':'User loses X/3 health','Description3':'','Description4':'','Description5':''},
{'Name':'Celestial Blast','Type':'Ether','Power':150,'Accuracy':100,'Uses':6,'Contact':true,'MoveType':'Special','Description1':'Deals X*Modifier damage to the opponent','Description2':'If user\'s health is [100%-75%), modifier is 1','Description3':'If user\'s health is [75%-50%), modifier is .75','Description4':'If user\'s health is [50%-25%), modifier is .5','Description5':'If user\'s health is [25%-0%), modifier is .25'},
{'Name':'Kamikaze','Type':'Air','Power':180,'Accuracy':100,'Uses':6,'Contact':false,'MoveType':'Physical','Description1':'User faints instantly','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Meteor Strike','Type':'Mineral','Power':90,'Accuracy':90,'Uses':12,'Contact':false,'MoveType':'Physical','Description1':'20% chance to increase +1 user\'s attack','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Stellar Rain','Type':'Light','Power':130,'Accuracy':95,'Uses':6,'Contact':true,'MoveType':'Special','Description1':'Decreases -1 opponent\'s Special Attack','Description2':'Decreases -1 opponent\'s Special Defense','Description3':'','Description4':'','Description5':''},
{'Name':'Giga Drainage','Type':'Plant','Power':80,'Accuracy':100,'Uses':12,'Contact':true,'MoveType':'Special','Description1':'Deals X damage to the opponent','Description2':'User recovers X/2 health','Description3':'','Description4':'','Description5':''},
{'Name':'Scorch','Type':'Fire','Power':40,'Accuracy':100,'Uses':42,'Contact':true,'MoveType':'Special','Description1':'20% chance to burn the opponent','Description2':'10% chance to decrease -1 opponent\'s Precision','Description3':'','Description4':'','Description5':''},
{'Name':'Ember','Type':'Fire','Power':60,'Accuracy':100,'Uses':30,'Contact':true,'MoveType':'Special','Description1':'30% chance to burn the opponent','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Toxic Stinger','Type':'Corrupt','Power':35,'Accuracy':100,'Uses':30,'Contact':false,'MoveType':'Physical','Description1':'50% chance to poison the opponent','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Drill Vine','Type':'Plant','Power':25,'Accuracy':90,'Uses':12,'Contact':false,'MoveType':'Physical','Description1':'The user attacks with 2-5 vines','Description2':'Each vine does 25 damage','Description3':'','Description4':'','Description5':''},
{'Name':'Mordant Strike','Type':'Monster','Power':60,'Accuracy':90,'Uses':24,'Contact':false,'MoveType':'Physical','Description1':'50% chance to decrease -1 opponent\'s Defense','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Wild Vine','Type':'Plant','Power':95,'Accuracy':85,'Uses':12,'Contact':true,'MoveType':'Physical','Description1':'40% chance to decrease -1 opponent\'s Defense','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Hard Rock','Type':'Mineral','Power':0,'Accuracy':100,'Uses':42,'Contact':false,'MoveType':'Status','Description1':'Increases +1 user\'s Attack','Description2':'Increases +1 user\'s Defense','Description3':'','Description4':'','Description5':''},
{'Name':'Recharge','Type':'Mineral','Power':0,'Accuracy':100,'Uses':12,'Contact':false,'MoveType':'Status','Description1':'Increases +1 user\'s Special Attack','Description2':'User gains maxHP/3 health','Description3':'','Description4':'','Description5':''},
{'Name':'Hydroshot','Type':'Water','Power':60,'Accuracy':100,'Uses':30,'Contact':true,'MoveType':'Special','Description1':'','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Ostracism','Type':'Monster','Power':0,'Accuracy':100,'Uses':24,'Contact':false,'MoveType':'Status','Description1':'Increases +1 user\'s Special Attack','Description2':'Increases +1 user\'s Special Defense','Description3':'','Description4':'','Description5':''},
{'Name':'Water Pressure','Type':'Water','Power':80,'Accuracy':100,'Uses':18,'Contact':false,'MoveType':'Physical','Description1':'20% chance to decrease -1 opponent\'s Defense','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Iron Wing','Type':'Mineral','Power':75,'Accuracy':90,'Uses':24,'Contact':false,'MoveType':'Physical','Description1':'20% chance to increase +1 user\'s Defense','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Sonic Bang','Type':'Mineral','Power':90,'Accuracy':100,'Uses':12,'Contact':true,'MoveType':'Special','Description1':'20% chance to confuse the opponent','Description2':'','Description3':'','Description4':'','Description5':''},
{'Name':'Spectral Charge','Type':'Dark','Power':90,'Accuracy':100,'Uses':18,'Contact':false,'MoveType':'Physical','Description1':'Deals X damage to the opponent','Description2':'User loses X/4 health','Description3':'','Description4':'','Description5':''},
{'Name':'Mystic Fire','Type':'Fire','Power':95,'Accuracy':95,'Uses':6,'Contact':false,'MoveType':'Physical','Description1':'40% chance to decrease -1 opponent\'s Special Attack','Description2':'','Description3':'','Description4':'','Description5':''},
];
export default Moves;