import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import App,{loader as appLoader,} from './App';
import EvoList from './pages/EvoList'
import Home from './pages/Home'
import TypeChart from './pages/TypeChart'
import EvoAttributes from './pages/EvoAttributes'
import EggAttributes from './pages/EggAttributes'
import Breeding from './pages/Breeding'
import Natures from './pages/Natures'
import DualTypeChart from './pages/DualTypeChart'
import MoveList from './pages/Moves'
import Sales from './pages/Sales'
import SalesHistory from './pages/SalesHistory'
import Stats from './pages/Stats'
import FAQs from './pages/FAQs'
import Sitemap from './Sitemap'
import Evo,{loader as evoLoader,} from './pages/Evo'
import reportWebVitals from './reportWebVitals';


const router = createBrowserRouter([
  // {
  //   path: "sitemap.xml",
  //   element: <Sitemap />,
  // },
  {
    path: "/",
    element: <App />,
    loader: appLoader,
    children: [
      {
        path: "all-evos",
        element: <EvoList />
      },
      {
        path: "evo/:evoName",
        element: <Evo />,
        loader: evoLoader 
      },
      {
        path: "type-chart",
        element: <TypeChart />
      },
      {
        path: "dual-type-chart",
        element: <DualTypeChart />
      },
      {
        path: "moves",
        element: <MoveList />
      },
      {
        path: "sales",
        element: <Sales />
      },
      {
        path: "sale-history",
        element: <SalesHistory />
      },
      {
        path: "faqs",
        element: <FAQs />
      },
      {
        path: "natures",
        element: <Natures />
      },
      {
        path: "evo-attributes",
        element: <EvoAttributes />
      },
      {
        path: "evo-egg-attributes",
        element: <EggAttributes />
      },
      {
        path: "stats",
        element: <Stats />
      },
      {
        path: "breeding",
        element: <Breeding />
      },
      {
        index: true, 
        element: <Home /> 
      }
    ]
  },
]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
