const Evos = [
{'ID':10, 'Species':'Adhamandra','PrimaryType':'Dark','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.7,'AvailableMoves':['Scratch','Leer','Tackle','Muddy Blast','Slash','Poison Arrow','Ominous Pulse']},
{'ID':11, 'Species':'Ainepolux','PrimaryType':'Light','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.6,'AvailableMoves':['Tackle','Analysis','Light Flash','Drainage','Healing Pulse','Mega Drainage','Light Burst','Solar Blast']},
{'ID':75, 'Species':'Allacrow','PrimaryType':'Air','SecondaryType':'Corrupt','ChanceToSummon':0.00701754385964912,'ChanceToSummonFemale':0.2,'AvailableMoves':['Tackle','Leer','Sturdiness','Iron Wing','Lunge','Raptor Charge']},
{'ID':7, 'Species':'Arnoriel','PrimaryType':'Air','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.5,'AvailableMoves':['Tackle','Dust Cloud','Wing Smack','Muddy Blast','Kamikaze']},
{'ID':117, 'Species':'Athel','PrimaryType':'Ether','SecondaryType':'','ChanceToSummon':0.0105263157894737,'ChanceToSummonFemale':0.7,'AvailableMoves':['Tackle','Analysis','Squall','Flower Cure']},
{'ID':45, 'Species':'Aubelyon','PrimaryType':'Earth','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.6,'AvailableMoves':['Tackle','Sturdiness','Roar','Growth','Ancestral Beam','Seed Shot','Fury Dance']},
{'ID':16, 'Species':'Beldar','PrimaryType':'Bug','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.5,'AvailableMoves':['Tackle','Silk Shot','Silk Cloak','Shedding','Slimy Armor','Suction']},
{'ID':84, 'Species':'Caerthos','PrimaryType':'Monster','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.4,'AvailableMoves':['Leer','Scratch','Savage Cut','Roar','Hardy Claw','Double Chop','Slash']},
{'ID':9, 'Species':'Carcoid','PrimaryType':'Water','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.3,'AvailableMoves':['Leer','Tackle','Bubble Bomb','Savage Cut','Curl Up','Torpedo','Water Pressure']},
{'ID':37, 'Species':'Clocarstone','PrimaryType':'Bug','SecondaryType':'Mineral','ChanceToSummon':0.012280701754386,'ChanceToSummonFemale':0.8,'AvailableMoves':['Tackle','Sturdiness','Silk Shot','Shedding','Silk Cloak','Diamond Charge']},
{'ID':100, 'Species':'Cyarabat','PrimaryType':'Dark','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.3,'AvailableMoves':['Scratch','Leer','Savage Cut','Rancor Blow','Bite','Slash']},
{'ID':106, 'Species':'Cyzorak','PrimaryType':'Corrupt','SecondaryType':'','ChanceToSummon':0.0140350877192982,'ChanceToSummonFemale':0.5,'AvailableMoves':['Tackle','Analysis','Leer','Hardy Claw','Frantic Charge','Lunge']},
{'ID':89, 'Species':'Dhaek','PrimaryType':'Monster','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.5,'AvailableMoves':['Tackle','Analysis','Swagger','Triple Attack','Kick Attack']},
{'ID':28, 'Species':'Droserace','PrimaryType':'Plant','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.4,'AvailableMoves':['Leer','Savage Cut','Drainage','Drill Vine','Mega Drainage','Mordant Strike','Wild Vine']},
{'ID':50, 'Species':'Espyke','PrimaryType':'Bug','SecondaryType':'Earth','ChanceToSummon':0.012280701754386,'ChanceToSummonFemale':0.5,'AvailableMoves':['Scratch','Drainage','Curl Up','Bug Bite','Bite','Suction','Seismic Blitz']},
{'ID':6, 'Species':'Eulocelus','PrimaryType':'Earth','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.6,'AvailableMoves':['Tackle','Curl Up','Hardy Claw','Seed Shot','Lunge','Mallet Smash','Rockiness','Forest Mace']},
{'ID':81, 'Species':'Fayde','PrimaryType':'Plant','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.5,'AvailableMoves':['Tackle','Drainage','Flash','Mega Drainage','Ancestral Beam','Giga Drainage']},
{'ID':2, 'Species':'Finantis','PrimaryType':'Bug','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.5,'AvailableMoves':['Sharpen','Tackle','Bug Bite','Zen Fury','Double Chop','Fury Dance']},
{'ID':19, 'Species':'Firemon','PrimaryType':'Fire','SecondaryType':'Monster','ChanceToSummon':0.012280701754386,'ChanceToSummonFemale':0.2,'AvailableMoves':['Scratch','Leer','Scorch','Ember','Slash','Mordant Strike']},
{'ID':46, 'Species':'Flint','PrimaryType':'Fire','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.4,'AvailableMoves':['Tackle','Leer','Scorch','Roar','Mordant Strike','Ember','Bite']},
{'ID':97, 'Species':'Fluozacil','PrimaryType':'Light','SecondaryType':'Water','ChanceToSummon':0.012280701754386,'ChanceToSummonFemale':0.5,'AvailableMoves':['Tackle','Analysis','Bubble Bomb','Curl Up','Flash','Water Pressure','Foam Blast','Healing Pulse','Light Burst']},
{'ID':55, 'Species':'Fugush','PrimaryType':'Bug','SecondaryType':'Water','ChanceToSummon':0.012280701754386,'ChanceToSummonFemale':0.8,'AvailableMoves':['Tackle','Bubble Bomb','Sharpen','Bite','Suction','Osmosis']},
{'ID':122, 'Species':'Geckaiba','PrimaryType':'Monster','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.3,'AvailableMoves':['Scratch','Leer','Scorch','Roar','Hardy Claw','Ember','Slash','Foam Blast']},
{'ID':63, 'Species':'Gemarites','PrimaryType':'Mineral','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.3,'AvailableMoves':['Tackle','Sturdiness','Sharpen','Mordant Strike','Slash','Rockiness','Diamond Charge']},
{'ID':53, 'Species':'Ghorgon','PrimaryType':'Dark','SecondaryType':'Monster','ChanceToSummon':0.012280701754386,'ChanceToSummonFemale':0.2,'AvailableMoves':['Scratch','Analysis','Savage Cut','Roar','Hardy Claw','Slash','Mordant Strike']},
{'ID':33, 'Species':'Gwenbee','PrimaryType':'Air','SecondaryType':'Bug','ChanceToSummon':0.012280701754386,'ChanceToSummonFemale':0.5,'AvailableMoves':['Analysis','Tackle','Drainage','Wing Smack','Mega Drainage','Buzz','Suction','Pollination']},
{'ID':43, 'Species':'Hikarul','PrimaryType':'Light','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.5,'AvailableMoves':['Tackle','Light Flash','Healing Pulse','Fleeting Shine','Foam Blast','Light Burst']},
{'ID':5, 'Species':'Hodeon','PrimaryType':'Corrupt','SecondaryType':'','ChanceToSummon':0.0140350877192982,'ChanceToSummonFemale':0.4,'AvailableMoves':['Tackle','Leer','Rip','Mean Bite','Roar','Venom Bite','Stamp']},
{'ID':103, 'Species':'Istral','PrimaryType':'Ether','SecondaryType':'','ChanceToSummon':0.0105263157894737,'ChanceToSummonFemale':0.7,'AvailableMoves':['Wing Smack','Analysis','Dust Storm','Celestial Blast','Stellar Charge','Nitro Flame','Lunge']},
{'ID':77, 'Species':'Jokull','PrimaryType':'Earth','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.5,'AvailableMoves':['Tackle','Analysis','Triple Attack','Sturdiness','Rancor Blow','Lunge','Seismic Blitz']},
{'ID':109, 'Species':'Kaos','PrimaryType':'Dark','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.5,'AvailableMoves':['Leer','Flash','Cynicism','Evil Sphere','Warming Light','Dark Force','Ominous Pulse']},
{'ID':12, 'Species':'Kapryno','PrimaryType':'Plant','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.6,'AvailableMoves':['Tackle','Analysis','Drainage','Sharpen','Mega Drainage','Growth','Seismic Blitz']},
{'ID':3, 'Species':'Karoite','PrimaryType':'Mineral','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.5,'AvailableMoves':['Hard Rock','Tackle','Sharpen','Rockiness','Sonic Bang','Diamond Charge']},
{'ID':30, 'Species':'Kerval','PrimaryType':'Earth','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.5,'AvailableMoves':['Analysis','Tackle','Dust Cloud','Kick Attack','Sharpen','Stamp']},
{'ID':13, 'Species':'Kitsul','PrimaryType':'Fire','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.6,'AvailableMoves':['Tackle','Light Flash','Scorch','Warming Light','Leer','Fleeting Shine','Lunge','Dark Force','Mystic Fire']},
{'ID':35, 'Species':'Krokon','PrimaryType':'Water','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.2,'AvailableMoves':['Leer','Scratch','Savage Cut','Bubble Bomb','Muddy Blast','Hardy Claw','Torpedo','Slash']},
{'ID':120, 'Species':'Lamphal','PrimaryType':'Bug','SecondaryType':'Light','ChanceToSummon':0.012280701754386,'ChanceToSummonFemale':0.5,'AvailableMoves':['Flash','Analysis','Drainage','Silk Shot','Buzz','Mega Drainage','Light Burst','Silk Cloak','Pollination']},
{'ID':59, 'Species':'Lounn','PrimaryType':'Light','SecondaryType':'Plant','ChanceToSummon':0.012280701754386,'ChanceToSummonFemale':0.8,'AvailableMoves':['Tackle','Drainage','Flash','Mega Drainage','Healing Pulse','Ancestral Beam']},
{'ID':22, 'Species':'Lumi','PrimaryType':'Light','SecondaryType':'Water','ChanceToSummon':0.012280701754386,'ChanceToSummonFemale':0.4,'AvailableMoves':['Tackle','Analysis','Bubble Bomb','Light Flash','Stellar Radiance','Foam Blast','Healing Pulse','Light Burst','Fleeting Shine']},
{'ID':96, 'Species':'Meissa','PrimaryType':'Light','SecondaryType':'','ChanceToSummon':0.012280701754386,'ChanceToSummonFemale':0.5,'AvailableMoves':['Tackle','Fi B','Flash','Lunge','Stellar Radiance','Cyclone','Lunar Beam']},
{'ID':54, 'Species':'Mellio','PrimaryType':'Water','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.5,'AvailableMoves':['Tackle','Bubble Bomb','Fleeting Shine','Muddy Blast','Foam Blast','Slimy Armor']},
{'ID':91, 'Species':'Metheo','PrimaryType':'Mineral','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.5,'AvailableMoves':['Tackle','Sturdiness','Healing Pulse','Stellar Charge','Meteor Strike','Stellar Rain']},
{'ID':64, 'Species':'Methyst','PrimaryType':'Dark','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.6,'AvailableMoves':['Evil Sphere','Leer','Sharpen','Muddy Blast','Cynicism','Spectral Charge']},
{'ID':51, 'Species':'Mobyd','PrimaryType':'Monster','SecondaryType':'Water','ChanceToSummon':0.012280701754386,'ChanceToSummonFemale':0.2,'AvailableMoves':['Scratch','Bubble Bomb','Torpedo','Roar','Mordant Strike','Slash']},
{'ID':69, 'Species':'Moffunap','PrimaryType':'Earth','SecondaryType':'Water','ChanceToSummon':0.012280701754386,'ChanceToSummonFemale':0.9,'AvailableMoves':['Tackle','Deep Sleep','Bubble Bomb','Muddy Blast','Ancestral Beam','Osmosis']},
{'ID':56, 'Species':'Morphee','PrimaryType':'Bug','SecondaryType':'Dark','ChanceToSummon':0.012280701754386,'ChanceToSummonFemale':0.7,'AvailableMoves':['Bug Bite','Silk Cloak','Evil Sphere','Silk Shot']},
{'ID':1, 'Species':'Nissel','PrimaryType':'Monster','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.2,'AvailableMoves':['Tackle','Fi B','Sharpen','Hardy Claw','Ostracism','Slash','Mordant Strike','Cynicism']},
{'ID':4, 'Species':'Nuvea','PrimaryType':'Ether','SecondaryType':'','ChanceToSummon':0.0105263157894737,'ChanceToSummonFemale':0.5,'AvailableMoves':['Healing Pulse','Fleeting Shine','Plasma Beam','Stellar Charge']},
{'ID':70, 'Species':'Nymphel','PrimaryType':'Air','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.5,'AvailableMoves':['Tackle','Dust Cloud','Squall','Ostracism','Cyclone']},
{'ID':93, 'Species':'Nythe','PrimaryType':'Mineral','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.5,'AvailableMoves':['Scratch','Sharpen','Hardy Claw','Polish','Slash','Lunge']},
{'ID':20, 'Species':'Obryos','PrimaryType':'Earth','SecondaryType':'Plant','ChanceToSummon':0.012280701754386,'ChanceToSummonFemale':0.5,'AvailableMoves':['Tackle','Analysis','Drainage','Dust Cloud','Mega Drainage','Growth','Lunge','Seed Shot','Kick Attack']},
{'ID':86, 'Species':'Obsy','PrimaryType':'Bug','SecondaryType':'Plant','ChanceToSummon':0.012280701754386,'ChanceToSummonFemale':0.2,'AvailableMoves':['Tackle','Silk Shot','Bug Bite','Drainage']},
{'ID':25, 'Species':'Onydae','PrimaryType':'Air','SecondaryType':'Dark','ChanceToSummon':0.012280701754386,'ChanceToSummonFemale':0.4,'AvailableMoves':['Tackle','Wing Smack','Savage Cut','Swagger','Hypnotism','Dark Force','Lunar Beam','Moon Recovery']},
{'ID':15, 'Species':'Rattuos','PrimaryType':'Earth','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.6,'AvailableMoves':['Scratch','Curl Up','Leer','Rockiness','Slash']},
{'ID':83, 'Species':'Ruard','PrimaryType':'Fire','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.4,'AvailableMoves':['Tackle','Leer','Sharpen','Bite','Slash','Mordant Strike']},
{'ID':85, 'Species':'Ryomizu','PrimaryType':'Ether','SecondaryType':'Water','ChanceToSummon':0.00526315789473684,'ChanceToSummonFemale':0.7,'AvailableMoves':['Tackle','Bubble Bomb','Hydroshot','Ostracism','Plasma Beam','Foam Blast']},
{'ID':125, 'Species':'Sauderon','PrimaryType':'Corrupt','SecondaryType':'Light','ChanceToSummon':0.00701754385964912,'ChanceToSummonFemale':0.3,'AvailableMoves':['Tackle','Leer','Dark Fire','Corrosion','Fi B','Corrupted Beam','Hardy Claw','Light Burst','Strategy','Acid Shot','High Voltage']},
{'ID':34, 'Species':'Shazark','PrimaryType':'Earth','SecondaryType':'Monster','ChanceToSummon':0.012280701754386,'ChanceToSummonFemale':0.4,'AvailableMoves':['Analysis','Tackle','Savage Cut','Sharpen','Mordant Strike','Lunge']},
{'ID':26, 'Species':'Skycyx','PrimaryType':'Air','SecondaryType':'Earth','ChanceToSummon':0.012280701754386,'ChanceToSummonFemale':0.7,'AvailableMoves':['Dust Cloud','Tackle','Dust Storm','Sharpen','Kick Attack','Nitro Flame','Raptor Charge']},
{'ID':102, 'Species':'Struthor','PrimaryType':'Air','SecondaryType':'Earth','ChanceToSummon':0.012280701754386,'ChanceToSummonFemale':0.1,'AvailableMoves':['Sharpen','Tackle','Leer','Dust Storm','Kick Attack']},
{'ID':40, 'Species':'Sunopendra','PrimaryType':'Bug','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.7,'AvailableMoves':['Tackle','Silk Shot','Curl Up','Venom Bite','Lunge','Silk Cloak','Shedding']},
{'ID':66, 'Species':'Tamandu','PrimaryType':'Earth','SecondaryType':'Fire','ChanceToSummon':0.012280701754386,'ChanceToSummonFemale':0.2,'AvailableMoves':['Scratch','Analysis','Scorch','Lunge','Slash']},
{'ID':39, 'Species':'Tokaleaf','PrimaryType':'Mineral','SecondaryType':'Plant','ChanceToSummon':0.012280701754386,'ChanceToSummonFemale':0.6,'AvailableMoves':['Analysis','Tackle','Hard Rock','Drainage','Mega Drainage','Recharge']},
{'ID':68, 'Species':'Tytan','PrimaryType':'Mineral','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.5,'AvailableMoves':['Analysis','Sharpen','Lunge','Stamp','Diamond Charge']},
{'ID':61, 'Species':'Uzumebach','PrimaryType':'Bug','SecondaryType':'Corrupt','ChanceToSummon':0.00701754385964912,'ChanceToSummonFemale':0.5,'AvailableMoves':['Sharpen','Bug Bite','Wing Smack','Toxic Stinger']},
{'ID':48, 'Species':'Venuserpien','PrimaryType':'Corrupt','SecondaryType':'Fire','ChanceToSummon':0.012280701754386,'ChanceToSummonFemale':0.3,'AvailableMoves':['Leer','Savage Cut','Scorch','Corrosion','Mean Bite','Ember','Corrupted Beam','Slash']},
{'ID':78, 'Species':'Vulpyro','PrimaryType':'Fire','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.5,'AvailableMoves':['Tackle','Analysis','Scorch','Flash','Ember']},
{'ID':42, 'Species':'Yotnar','PrimaryType':'Monster','SecondaryType':'','ChanceToSummon':0.0175438596491228,'ChanceToSummonFemale':0.3,'AvailableMoves':['Scratch','Fi B','Sharpen','Hardy Claw','Slash','Mordant Strike','Cynicism']}
];

export default Evos;