
import React from "react";
import { Link } from "react-router-dom";

function EggAttributes() {
  return (
    <>
      <section class="single section-sm pb-0">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="p-lg-5 p-4 bg-white">
                <h2 class="mb-5">Evo Egg Attributes</h2>
                <div class="row">
                  <div class="col-xs-12 col-sm-4">
                    <img src="./assets/images/Egg Card.PNG" class="img-fluid" />
                  </div>
                  <div class="col-xs-12 col-sm-8">
                    <p>Each Evo Egg has a number of attributes that influence its ability in battle and its overall value.  You can see which attributes are shown on the Evo Egg card in the diagram.</p>
                  </div>
                </div>
                <div class="content">
                  <hr />
                  <h5 class="mb-5">General Information</h5>
                  <div class="row">
                    <div class="col-xs-12 col-sm-4">
                      <img src="./assets/images/Egg General Information.PNG" class="img-fluid" />
                    </div>
                    <div class="col-xs-12 col-sm-8">
                      <p>The General Information section of the Evo Egg screen shows the following attributes</p>
                      <ul>
                        <li>Nickname
                          <ul><li>The species of the Evo Egg combined with their Evo number</li></ul>
                        </li>
                        <li>Evo Name
                          <ul><li>The species of the Evo Egg, the species will dictate species-specific attributes and base stats.</li></ul>
                        </li>
                        <li>Owner
                          <ul><li>The profile name of the owner of the Evo Egg</li></ul>
                        </li>
                        <li>Primary Type
                          <ul><li>The Evo's primary type.  An Evo's type(s) affects which types of attacks are effective against it (see <Link to="/type-chart">type chart</Link>), which move types it will gain Same Type Attack Bonus (STAB) with, and what other Evos it can breed with (see <Link to="/breeding">breeding page</Link>).</li></ul>
                        </li>
                        <li>Secondary Type
                          <ul><li>The Evo's secondary type.  There is no functional difference between a primary and seconary type.  An Evo's type(s) affects which types of attacks are effective against it (see <Link to="/type-chart">type chart</Link>), which move types it will gain Same Type Attack Bonus (STAB) with, and what other Evos it can breed with (see <Link to="/breeding">breeding page</Link>).</li></ul>
                        </li>
                        <li>Generation
                          <ul><li>The generation of the Evo, which dictates breeding costs and the total breeds of an Evo.  See the <Link to="/breeding">breeding page</Link> for more information.</li></ul>
                        </li>
                        <li>Treated
                          <ul><li>Whether or not the Evo Egg has been treated, which increases the chance of getting a higher rarity Evo when the egg is hatched.  See the <Link to="/breeding">breeding page</Link> for more information.</li></ul>
                        </li>
                        <li>Parent 1
                          <ul><li>The number of the first parent Evo used when breeding the egg.</li></ul>
                        </li>
                        <li>Parent 2
                          <ul><li>The number of the second parent Evo used when breeding the egg.</li></ul>
                        </li>
                        <li>Created At
                          <ul><li>The time that the egg was created.</li></ul>
                        </li>
                        <li>Incubation
                          <ul><li>The remaining time until this egg can hatch.  See the <Link to="/breeding">breeding page</Link> for more information.</li></ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <hr />
                  <h5 class="mb-5">Inherited Boosts</h5>
                  <div class="row">
                    <div class="col-xs-12 col-sm-4">
                      <img src="./assets/images/Inherited Boosts.PNG" class="img-fluid" />
                    </div>
                    <div class="col-xs-12 col-sm-8">
                      <p>The Inherited Boosts section of the Evo Egg screen shows the following attributes.  The values shown are "boosts" to the Evo's minimum IVs when they hatch and are based on the parent's IVs, not random.  Only eggs that are generation 1 have Inherited Boosts above 0.  For more information see the <Link to="/breeding">breeding page</Link>.</p>
                      <ul>
                        <li>Attack (ATK)
                          <ul><li>The attack IV boost of the Evo.  The overall attack stat dictates how much damage Physical based attacks will do when the Evo attacks.  See the <Link to="/stats">stats page</Link> for more information.</li></ul>
                        </li>
                        <li>Defense (DEF)
                          <ul><li>The defense IV boost of the Evo.  The overall defense stat dictates how much damage Physical based attacks will do when the Evo defends.  See the <Link to="/stats">stats page</Link> for more information.</li></ul>
                        </li>
                        <li>Special (Sp. ATK)
                          <ul><li>The special IV boost of the Evo.  The overall special stat dictates how much damage Special based attacks will do when the Evo attacks.  See the <Link to="/stats">stats page</Link> for more information.</li></ul>
                        </li>
                        <li>Resistance (Sp. DEF)
                          <ul><li>The resistance IV boost of the Evo.  The overall resistance stat dictates how much damage Special based attacks will do when the Evo defends.  See the <Link to="/stats">stats page</Link> for more information.</li></ul>
                        </li>
                        <li>Speed
                          <ul><li>The speed IV boost of the Evo.  The overall speed stat dictates which Evo goes first per turn after Status and Priority moves are taken into account.  See the <Link to="/stats">stats page</Link> for more information.</li></ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default EggAttributes;