import React from "react";
import Evos from "../Evos";
import Moves from "../Moves";
import { useLoaderData } from "react-router-dom";

export async function loader({ params }) {
  const matches = Evos.filter(function (evo) {
    return evo.Species.toLowerCase() == params.evoName.toLowerCase();
  });
  if (matches.length > 0) {
    const match = matches[0]
    match.MovesFull = [];
    match.AvailableMoves.forEach(m => {
      const fullMove = Moves.filter(move => { return move.Name == m });
      if (fullMove.length > 0) {
        match.MovesFull.push(fullMove[0]);
      }
      else {
        match.MovesFull.push({ 'Name': m });
      }
    });
    return match;
  }
  else {
    return null;
  }
}

function Evo() {
  const evo = useLoaderData();
  const rows = [];
  evo.MovesFull.forEach(move => {
    rows.push(<tr>
      <td>{move.Name}</td>
      <td style={{ borderRight: '0px' }} class={move.Type.toLowerCase() + "-icon type-icon"}></td>
      <td style={{ borderLeft: '0px' }}>{move.Type}</td>
      <td>{move.MoveType}</td>
      <td>{(move.MoveType == "Status" ? "" : move.Power)}</td>
      <td>{(move.MoveType == "Status" ? "" : move.Accuracy + "%")}</td>
      <td>{move.Uses}</td>
      <td>{(move.Contact ? "Contact" : 'No Contact')}</td><td>
        {(move.Description1 != "" ? "- " + move.Description1 : "")}{move.Description1 != "" && <br />}
        {(move.Description2 != "" ? "- " + move.Description2 : "")}{move.Description2 != "" && <br />}
        {(move.Description3 != "" ? "- " + move.Description3 : "")}{move.Description3 != "" && <br />}
        {(move.Description4 != "" ? "- " + move.Description4 : "")}{move.Description4 != "" && <br />}
        {(move.Description5 != "" ? "- " + move.Description5 : "")}{move.Description5 != "" && <br />}
      </td></tr>);
  });
  return (
    <section class="single section-sm pb-0">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="p-lg-5 p-4 bg-white">
              <h2 class="mb-5">{evo.Species}</h2>
              <p>{evo.Species} is a {(evo.SecondaryType ? evo.PrimaryType + " / " + evo.SecondaryType : evo.PrimaryType)} type Evo.</p>
              <div type="content">
                <div class="row">
                  <div id="appearanceCarousel" class="carousel slide col-xs-12 col-sm-6" data-ride="carousel" style={{ background: "grey" }}>
                    <ol class="carousel-indicators">
                      <li data-target="#appearanceCarousel" data-slide-to="0" class="active"></li>
                      <li data-target="#appearanceCarousel" data-slide-to="1"></li>
                      <li data-target="#appearanceCarousel" data-slide-to="2"></li>
                      <li data-target="#appearanceCarousel" data-slide-to="3"></li>
                    </ol>
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        <img src={'https://images.evoverses.com/card/evo/' + evo.Species + '/Normal'} class="d-block img-fluid" />
                        <div class="carousel-caption">
                          <h6>Normal</h6>
                        </div>
                      </div>
                      <div class="carousel-item">
                        <img src={'https://images.evoverses.com/card/evo/' + evo.Species + '/Egg'} class="d-block img-fluid" />
                        <div class="carousel-caption">
                          <h6>Egg</h6>
                        </div>
                      </div>
                      <div class="carousel-item">
                        <img src={'https://images.evoverses.com/card/evo/' + evo.Species + '/Epic'} class="d-block img-fluid" />
                        <div class="carousel-caption">
                          <h6>Epic</h6>
                        </div>
                      </div>
                      <div class="carousel-item">
                        <img src={'https://images.evoverses.com/card/evo/' + evo.Species + '/Chroma'} class="d-block img-fluid" />
                        <div class="carousel-caption">
                          <h6>Chroma</h6>
                        </div>
                      </div>
                    </div>
                    <a class="carousel-control-prev" href="#appearanceCarousel" role="button" data-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#appearanceCarousel" role="button" data-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="sr-only">Next</span>
                    </a>
                  </div>
                  <div class="col-xs-12 col-sm-6">
                    <h5>Evo Data</h5>
                    <div class="responsive-table">
                      <table>
                        <tbody>
                          <tr><th>#</th><td colspan="2">{evo.ID}</td></tr>
                          <tr>
                            <th>Primary Type</th>
                            <td style={{ borderRight: '0px' }} class={evo.PrimaryType.toLowerCase() + "-icon type-icon"}></td>
                            <td style={{ borderLeft: '0px' }}>{evo.PrimaryType}</td>
                          </tr>
                          <tr><th>Secondary Type</th>
                            <td style={{ borderRight: '0px' }} class={evo.SecondaryType.toLowerCase() + "-icon type-icon"}></td>
                            <td style={{ borderLeft: '0px' }}>{evo.SecondaryType}</td>
                          </tr>
                          <tr><th>% to Summon</th><td colspan="2">{(100.0 * evo.ChanceToSummon).toFixed(2)}%</td></tr>
                          <tr><th>% to Summon Female</th><td colspan="2">{(100.0 * evo.ChanceToSummonFemale).toFixed(0)}%</td></tr>
                          <tr><th>% to Summon Male</th><td colspan="2">{(100.0 * (1.0 - evo.ChanceToSummonFemale)).toFixed(0)}%</td></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <h5>Available Moves</h5>
                  <div class="table-responsive">
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th colspan="2">Damage Type</th>
                          <th>Move Type</th>
                          <th>Power</th>
                          <th>Accuracy</th>
                          <th>Uses</th>
                          <th>Contact</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rows}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Evo;