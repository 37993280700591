
import React from "react";
import { Link } from "react-router-dom";

function Sales() {
  return (
    <>
      <section class="single section-sm pb-0">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="p-lg-5 p-4 bg-white">
                <h2 class="mb-5">Current Sales</h2>
                <p>Put your Evo up for sale in the marketplace or buy a new one for $EVO.  Both fixed price and auction sales are available.  When bidding on an auction in the last 60 minutes of the auction time the auction will be extended for an additional 60 minutes.</p>
                <div class="content">
                <hr />
                <h5 class="mb-5">Sale List</h5>
                <p>Sales coming soon!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Sales;