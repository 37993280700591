import React, { useState } from "react";
import { Link } from "react-router-dom";

function Header() {
  return (
    <header class="shadow-bottom sticky-top bg-white">
    <nav class="navbar navbar-expand-md navbar-dark">
      <div class="container">
        <Link class="navbar-brand px-2" to={'/'} style={{color:"var(--primary-color)"}}><img src="https://cdn.discordapp.com/attachments/953702095293390878/954386922745266206/Frame_6.png" height="50px;" />EvoDex.net</Link>
        <button class="navbar-toggler border-0" type="button" data-toggle="collapse" data-target="#navigation"
          aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse text-center" id="navigation">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Evo Data</a>
              <div class="dropdown-menu">
                <Link class="dropdown-item" to={'/all-evos'}>All Evos</Link>
                <Link class="dropdown-item" to={'/evo-attributes'}>Evo Attributes</Link>
                <Link class="dropdown-item" to={'/evo-egg-attributes'}>Evo Egg Attributes</Link>
                <Link class="dropdown-item" to={'/moves'}>Moves</Link>
                <Link class="dropdown-item" to={'/type-chart'}>Type Chart</Link>
                <Link class="dropdown-item" to={'/dual-type-chart'}>Dual Type Chart</Link>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Mechanics</a>
              <div class="dropdown-menu">
                <Link class="dropdown-item" to={'/stats'}>Stats</Link>
                <Link class="dropdown-item" to={'/breeding'}>Breeding</Link>
                <Link class="dropdown-item" to={'/natures'}>Natures</Link>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Marketplace</a>
              <div class="dropdown-menu">
                <Link class="dropdown-item" to={'/sales'}>Current Sales</Link>
                <Link class="dropdown-item" to={'/sale-history'}>Sale History</Link>
              </div>
            </li>
            <li class="nav-item">
                <Link class="nav-link" to={'/faqs'}>FAQs</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
  );
}

export default Header;