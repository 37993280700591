
import React from "react";
import { Link } from "react-router-dom";

function SalesHistory() {
  return (
    <>
      <section class="single section-sm pb-0">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="p-lg-5 p-4 bg-white">
                <h2 class="mb-5">Sales History</h2>
                <p>Sale history coming soon!</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SalesHistory;