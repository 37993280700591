import React from "react";
import Evos from "../Evos";
import { Link } from "react-router-dom";

function EvoList() {
  const rows = [];
  Evos.forEach(evo => {
    rows.push(<tr>
      <td>{evo.ID}</td>
      <td style={{borderRight:'0px'}}><img class='evo-thumbnail' src={'https://images.evoverses.com/card/evo/' + evo.Species + '/Normal'} /></td>
      <td style={{borderLeft:'0px'}}><Link to={"/evo/"+evo.Species.toLowerCase()}>{evo.Species}</Link></td>
      <td style={{borderRight:'0px'}} class={evo.PrimaryType.toLowerCase()+"-icon type-icon"}></td>
      <td style={{borderLeft:'0px'}}>{evo.PrimaryType}</td>
      <td style={{borderRight:'0px'}} class={evo.SecondaryType.toLowerCase()+"-icon type-icon"}></td>
      <td style={{borderLeft:'0px'}}>{evo.SecondaryType}</td>
      <td>{(100.0 * evo.ChanceToSummon).toFixed(2)}%</td>
      </tr>);
  });
  return (
    <section class="single section-sm pb-0">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="p-lg-5 p-4 bg-white">
              <h2 class="mb-5">All Evos</h2>
              <p>View all Evo Species and their key characteristics.  These characteristics are the same for all Evos of the Species.</p>
              <div>
                <div class="table-responsive">
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th colspan="2">Species</th>
                        <th colspan="2">Primary Type</th>
                        <th colspan="2">Secondary Type</th>
                        <th>% to Summon</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rows}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EvoList;