import React from "react";
import { Link } from "react-router-dom";
import Moves from "../Moves";

function MoveList() {
  const rows = [];
  Moves.sort((move1, move2) => {return (move1.Name < move2.Name ? -1 : (move1.Name > move2.Name ? 1 : 0));}).forEach(move => {
    rows.push(<tr>
      <td>{move.Name}</td>
      <td style={{borderRight:'0px'}} class={move.Type.toLowerCase()+"-icon type-icon"}></td>
      <td style={{borderLeft:'0px'}}>{move.Type}</td>
      <td>{move.MoveType}</td>
      <td>{(move.MoveType=="Status" ? "" : move.Power)}</td>
      <td>{(move.MoveType=="Status" ? "" : move.Accuracy + "%")}</td>
      <td>{move.Uses}</td>
      <td>{(move.Contact ? "Contact" : 'No Contact')}</td><td>
      {(move.Description1 != "" ? "- " + move.Description1 : "")}{move.Description1 != "" && <br />}
      {(move.Description2 != "" ? "- " + move.Description2 : "")}{move.Description2 != "" && <br />}
      {(move.Description3 != "" ? "- " + move.Description3 : "")}{move.Description3 != "" && <br />}
      {(move.Description4 != "" ? "- " + move.Description4 : "")}{move.Description4 != "" && <br />}
      {(move.Description5 != "" ? "- " + move.Description5 : "")}{move.Description5 != "" && <br />}
      </td></tr>);
  });
  return (
    <>
      <section class="single section-sm pb-0">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="p-lg-5 p-4 bg-white">
                <h2 class="mb-5">Moves</h2>
                <p>Each Evo can learn species-specific moves as it levels up.  The overall list of moves is shared across Evo species, with many moves being available to multiple species.  Currently all the Evo's moves are available for battles as all Evos are set to level 100 for battling.</p>
                <div class="content">
                <hr />
                <h5 class="mb-5">Move Attributes</h5>
                <img src="./assets/images/Move.png" class="img-fluid" />
                <p>Each move has the following attributes that determine its effect.</p>
                <ul>
                  <li>Name
                    <ul><li>The name of the move.</li></ul>
                  </li>
                  <li>Damage Type
                    <ul><li>The Type of damage that the move does to the opponent in the case of a Physical or Special move.  This type is the attacking damage outlined on the <Link to="/type-chart">type chart</Link> and is impacted by the opponent's type(s).  If the damage type of the move matches one of the user's types then the damage gets an additional same type attack bonus (STAB), multiplier not currently known.</li></ul>
                  </li>
                  <li>Move Type
                    <ul><li>Can be either Physical, Special, or Status.  Physical moves do damage to the opponent and the amount of damage is impacted by the user's Attack stat and the opponent's Defense stat.  Special moves do damage to the opponent and the amount of damage is impacted by the user's Special stat and the opponent's Resistance stat.  Status moves do not do any damage and cause some other effect described in the Description.</li></ul>
                  </li>
                  <li>Power
                    <ul><li>The power level of the move, which impacts the amount of damage for a Physical or Special move.  Higher is better.</li></ul>
                  </li>
                  <li>Accuracy
                    <ul><li>The chance that the move has to hit the opponent, not including any battle modifiers such as Precision.  Status moves always have 100% accuracy.</li></ul>
                  </li>
                  <li>Uses
                    <ul><li>The amount of times the move can be used in the same battle.</li></ul>
                  </li>
                  <li>Contact
                    <ul><li>Whether or not the move requires contact.  If the user's Evo's size is larger the opponent's and the move is a Contact move there is a damage bonus, currently unknown.</li></ul>
                  </li>
                  <li>Description
                    <ul><li>The description of any affects of the move other than damage, or how the damage is modified from the normal amount.</li></ul>
                  </li>
                </ul>
                <hr />
                <h5 class="mb-5">Move List</h5>
                <p>All currently available moves are listed below.</p>
                <div class="table-responsive">
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th colspan="2">Damage Type</th>
                        <th>Move Type</th>
                        <th>Power</th>
                        <th>Accuracy</th>
                        <th>Uses</th>
                        <th>Contact</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rows}
                    </tbody>
                  </table>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MoveList;