
import React from "react";
import { Link } from "react-router-dom";

function EvoAttributes() {
  return (
    <>
      <section class="single section-sm pb-0">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="p-lg-5 p-4 bg-white">
                <h2 class="mb-5">Evo Attributes</h2>
                <div class="row">
                  <div class="col-xs-12 col-sm-4">
                    <img src="./assets/images/Evo Card.PNG" class="img-fluid" />
                  </div>
                  <div class="col-xs-12 col-sm-8">
                    <p>Each Evo NFT has a number of attributes that influence its ability in battle and its overall value.  You can see which attributes are shown on the Evo card in the diagram.</p>
                  </div>
                </div>
                <div class="content">
                  <hr />
                  <h5 class="mb-5">General Information</h5>
                  <div class="row">
                    <div class="col-xs-12 col-sm-4">
                      <img src="./assets/images/General Information.PNG" class="img-fluid" />
                    </div>
                    <div class="col-xs-12 col-sm-8">
                      <p>The General Information section of the Evo screen shows the following attributes</p>
                      <ul>
                        <li>Nickname
                          <ul><li>The species of the Evo combined with their Evo number</li></ul>
                        </li>
                        <li>Evo Name
                          <ul><li>The species of the Evo, the species will dictate species-specific attributes and base stats.</li></ul>
                        </li>
                        <li>Owner
                          <ul><li>The profile name of the owner of the Evo</li></ul>
                        </li>
                        <li>Level
                          <ul><li>The Evo's current level.  The Evo's stats will increase per level and evolutions and additional moves will be unlocked at higher levels.</li></ul>
                        </li>
                        <li>Total XP
                          <ul><li>The total amount of XP the Evo has gained</li></ul>
                        </li>
                        <li>Nature
                          <ul><li>The Evo's nature, which impacts stat growth of two stats.  See the <Link to="/natures">natures page</Link> for more information.</li></ul>
                        </li>
                        <li>Primary Type
                          <ul><li>The Evo's primary type.  An Evo's type(s) affects which types of attacks are effective against it (see <Link to="/type-chart">type chart</Link>), which move types it will gain Same Type Attack Bonus (STAB) with, and what other Evos it can breed with (see <Link to="/breeding">breeding page</Link>).</li></ul>
                        </li>
                        <li>Secondary Type
                          <ul><li>The Evo's secondary type.  There is no functional difference between a primary and seconary type.  An Evo's type(s) affects which types of attacks are effective against it (see <Link to="/type-chart">type chart</Link>), which move types it will gain Same Type Attack Bonus (STAB) with, and what other Evos it can breed with (see <Link to="/breeding">breeding page</Link>).</li></ul>
                        </li>
                        <li>Total Breeds
                          <ul><li>The total number of times the Evo can breed, based on its Generation.  See the <Link to="/breeding">breeding page</Link> for more information.</li></ul>
                        </li>
                        <li>Available Breeds
                          <ul><li>The remaining number of times the Evo can breed, based on its Generation.  See the <Link to="/breeding">breeding page</Link> for more information.</li></ul>
                        </li>
                        <li>Breed Cooldown
                          <ul><li>The remaining until this Evo can breed.  See the <Link to="/breeding">breeding page</Link> for more information.</li></ul>
                        </li>
                        <li>Generation
                          <ul><li>The generation of the Evo, which dictates breeding costs and the total breeds of an Evo.  See the <Link to="/breeding">breeding page</Link> for more information.</li></ul>
                        </li>
                        <li>Rarity
                          <ul><li>The rarity level of the Evo, can be Normal (white card border), Epic (golden card border), or Chroma (purple card border).</li></ul>
                        </li>
                        <li>Gender
                          <ul><li>The gender of the Evo, which dictates which other Evos it can breed with.  See the <Link to="/breeding">breeding page</Link> for more information.</li></ul>
                        </li>
                        <li>Size
                          <ul><li>The size of the Evo, effect current unknown.</li></ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <hr />
                  <h5 class="mb-5">Stats</h5>
                  <div class="row">
                    <div class="col-xs-12 col-sm-4">
                      <img src="./assets/images/Stats.PNG" class="img-fluid" />
                    </div>
                    <div class="col-xs-12 col-sm-8">
                      <p>The Stats section of the Evo screen shows the following attributes.  Please keep in mind that values shown are the Evo's IVs, not their actual stat values.  For more information see the <Link to="/stats">stats page</Link>.  The green colored stat is the one boosted by the Evo's nature and the red colored stat is the one hindered by the Evo's nature.  For more information see the <Link to="/natures">natures page</Link>.</p>
                      <ul>
                        <li>Health Points (HP)
                          <ul><li>The HP IV of the Evo.  The overall HP stat dictates how much HP the Evo has.  See the <Link to="/stats">stats page</Link> for more information.</li></ul>
                        </li>
                        <li>Attack (ATK)
                          <ul><li>The attack IV of the Evo.  The overall attack stat dictates how much damage Physical based attacks will do when the Evo attacks.  See the <Link to="/stats">stats page</Link> for more information.</li></ul>
                        </li>
                        <li>Defense (DEF)
                          <ul><li>The defense IV of the Evo.  The overall defense stat dictates how much damage Physical based attacks will do when the Evo defends.  See the <Link to="/stats">stats page</Link> for more information.</li></ul>
                        </li>
                        <li>Special (Sp. ATK)
                          <ul><li>The special IV of the Evo.  The overall special stat dictates how much damage Special based attacks will do when the Evo attacks.  See the <Link to="/stats">stats page</Link> for more information.</li></ul>
                        </li>
                        <li>Resistance (Sp. DEF)
                          <ul><li>The resistance IV of the Evo.  The overall resistance stat dictates how much damage Special based attacks will do when the Evo defends.  See the <Link to="/stats">stats page</Link> for more information.</li></ul>
                        </li>
                        <li>Speed
                          <ul><li>The speed IV of the Evo.  The overall speed stat dictates which Evo goes first per turn after Status and Priority moves are taken into account.  See the <Link to="/stats">stats page</Link> for more information.</li></ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default EvoAttributes;