
import React from "react";
import { Link } from "react-router-dom";

function Home() {
  return (
    <>
	<section class="section">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-12 text-center">
					<h1 class="section-title" style={{color:"var(--primary-color)"}}>EvoDex.net</h1>
          <h3 style={{color:"var(--white-color)"}}>Your source for all data on EvoVerses, the web3 battle game where you can collect and breed Evo NFTs to form your team and become the champion!</h3>
				</div>
				<div class="col-lg-4 col-sm-6 mb-4">
					<Link to="/all-evos" class="px-4 py-5 bg-white shadow text-center d-block match-height">
						<i class="ti-view-list-alt icon text-primary d-block mb-4"></i>
						<h3 class="mb-3 mt-0">All Evos</h3>
						<p class="mb-0">View the list of all Evos currently released and key attributes for each Evo.</p>
					</Link>
				</div>
				<div class="col-lg-4 col-sm-6 mb-4">
					<Link to="/type-chart" class="px-4 py-5 bg-white shadow text-center d-block match-height">
						<i class="ti-layout-grid4-alt icon text-primary d-block mb-4"></i>
						<h3 class="mb-3 mt-0">Type Chart</h3>
						<p class="mb-0">See how each Evo type matches up against the other.</p>
					</Link>
				</div>
				<div class="col-lg-4 col-sm-6 mb-4">
					<Link to="/evo-attributes" class="px-4 py-5 bg-white shadow text-center d-block match-height">
						<i class="ti-info icon text-primary d-block mb-4"></i>
						<h3 class="mb-3 mt-0">Evo Attributes</h3>
						<p class="mb-0">Learn about each of the visible attributes of an Evo.</p>
					</Link>
				</div>
			</div>
		</div>
	</section>
  <section>
		<div class="container">
			<div class="row">
				<div class="col-12">
					<div class="section px-3 bg-white shadow text-center">
						<h2 class="mb-4">Looking for the answer to a question?</h2>
						<p class="mb-4">View our list of frequently asked questions</p>
						<Link to="/faqs" class="btn btn-primary">View FAQs</Link>
					</div>
				</div>
			</div>
		</div>
	</section>
  </>
  );
}

export default Home;