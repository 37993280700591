import React, { useState } from "react";

function Footer() {
  return (
  <footer class="section pb-4">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-8 text-md-left text-center">
          <p class="mb-md-0 mb-4" style={{color:"var(--white-color)"}}>Copyright © 2022 Designed and Developed by Perrin01.  If you find value in this site please consider tipping $EVO to 0xAf1DB9F120f1446CE2780209d793cFd30B4C0526</p>
        </div>
      </div>
    </div>
  </footer>
  );
}

export default Footer;