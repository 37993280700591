
import React from "react";
import { Link } from "react-router-dom";

function FAQs() {
  return (
    <>
	 <section class="section pb-0">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="section-title text-primary">Frequently Asked Questions</h2>
          <div id="accordion">
            <div class="card mb-4 rounded-0 shadow border-0">
              <div class="card-header rounded-0 bg-white border p-0 border-0">
                <a class="card-link h4 d-flex tex-dark mb-0 py-3 px-4 justify-content-between" data-toggle="collapse"
                  href="#will-updates-also-be-free">
                  <span>Why is so much of the site "coming soon"?</span> <i class="ti-plus text-primary text-right"></i>
                </a>
              </div>
              <div id="will-updates-also-be-free" class="collapse" data-parent="#accordion">
                <div class="card-body font-secondary text-color">I am doing this in my spare time, so I can't make everything happen right away!  If you have feedback on what you'd like to see next provide it <a href="https://www.surveymonkey.com/r/5G7B5HJ">here</a>.</div>
              </div>
            </div>
            <div class="card mb-4 rounded-0 shadow border-0">
              <div class="card-header rounded-0 bg-white border p-0 border-0">
                <a class="card-link h4 d-flex tex-dark mb-0 py-3 px-4 justify-content-between" data-toggle="collapse"
                  href="#will-updates-also-be-free">
                  <span>How do I get more BOBA?</span> <i class="ti-plus text-primary text-right"></i>
                </a>
              </div>
              <div id="will-updates-also-be-free" class="collapse" data-parent="#accordion">
                <div class="card-body font-secondary text-color">If you are on the BOBA network you will need to use <a href="https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=0x4200000000000000000000000000000000000023&chainId=43288">legacy SushiSwap</a> to swap EVO or AVAX for BOBA.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  </>
  );
}

export default FAQs;