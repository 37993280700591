
import React from "react";
import { Link } from "react-router-dom";

function Stats() {
  return (
    <>
      <section class="single section-sm pb-0">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="p-lg-5 p-4 bg-white">
                <h2 class="mb-5">Stats</h2>
                <p>Each Evo has 6 stats that are based on the base stats of the Evo species as well as the IVs of the individual Evo.</p>
                <div class="content">
                <hr />
                <h5 class="mb-5">Base Stats</h5>
                <p>More details coming soon!</p>
                <hr />
                <h5 class="mb-5">IVs</h5>
                <p>More details coming soon!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Stats;